.comment-container-wrapper {
  padding: 10px;
  display: flex;
  flex-direction: column;
  max-height: 325px;
  overflow: auto;
  min-height: 85px;

  .comment-preview {
    margin-left: 32px;
    margin-top: -10px;

    .reply-number {
      width: 32px;
      height: 32px;
    }

    .reply-number {
      display: flex;
      align-items: center;
      color: rgba(255, 255, 255, 0.8);
      width: 100%;
    }
  }

  .max-right {
    max-width: 87%;

    .d-flex.comment-content {
      max-width: 100%;
      flex-direction: column;
      max-height: 100px;
      overflow: auto;
      padding-right: 2px;
    }
  }
}
