@import '../../scss/theme';

.order-filter-preset-wrapper {
  border-radius: 10px;
  border: 1px solid #ced4da;
  background: #fff;
  padding: 15px;
  margin: 15px;

  ul.order-preset-list {
    li {
      cursor: pointer;
      padding: 0px;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $darkblue;

      &.active {
        color: $darkblue;
        font-weight: bold;
      }

      .radio-input-container {
        position: relative;

        &:before {
          width: 14px;
          height: 14px;
          border-radius: 100%;
          background-color: #fff;
          border: 1px solid $darkblue;
          content: ' ';
          display: block;
        }

        .radio-input {
          position: absolute;
          left: 3px;
          right: 3px;
          top: 3px;
          bottom: 3px;

          &:before {
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background-color: $darkblue;
            border: 1px solid $darkblue;
            content: ' ';
            display: block;
          }
        }
      }

      .btn {
        .feather {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
