#viewer-container {
  width: 100vw;
  position: absolute;
  height: 100%;
  display: flex;
  padding: 0px 0px 0px 40px;
  background-color: #f8f7f7;
}
#pdf-tools-viewer {
  width: 100%;
  height: 100%;
  position: relative;
}
.pwv-navigation-toolbar-pageinput > label {
  margin-bottom: 0px;
}

button.pwv-ToolbarButton[title='Text'],
button.pwv-ToolbarButton[title='Stamp'],
button.pwv-ToolbarButton[title='Image Stamp'],
button.pwv-ToolbarButton[name='saveDocument'] {
  display: none;
}

.pwv-DocumentBar .btn-header-annotation-close {
  margin-left: -17px;
}

.btn-action {
  height: 21px;
  font-size: 10px !important;
  margin-top: 15px;
  margin-left: 5px;
  padding: 2px 5px;
}

.pdf-tools-file-name {
  vertical-align: middle;
  align-items: center;

  .badge-darkprimary {
    color: #fff;
    background-color: #009fb5;
    font-size: 10px;
    margin-left: 6px;

    .feather {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }
}

@media (max-width: 1024px) {
  #viewer-container {
    width: 100vw;
  }
}
