@import '../../../../scss/theme';

.comment-edit-wrapper {
  padding: 10px;

  .title {
    color: rgba(255, 255, 255, 0.9);
  }

  .old-comment {
    font-size: 11px;
    color: rgba(255, 255, 255, 0.64);
    max-height: 100px;
    overflow: auto;
  }

  .btn {
    &:active,
    &:focus {
      box-shadow: none;
    }
  }

  .public-DraftEditorPlaceholder-root {
    position: relative;
  }

  .edit-comment-editor {
    cursor: text;
    min-height: 22px;
    margin: 10px 0;
    width: 100%;
    color: rgba(255, 255, 255, 0.9);
  }

  .mentioned-user {
    background-color: $darkblue;
    padding: 1px 5px;
    border-radius: 15px;
  }

  .draftJsEmojiPlugin__emojiSuggestions__2ffcV {
    background-color: #484d52;
    border: 1px solid #62666b;
    box-shadow: none;
    border-radius: 8px;
    min-height: 40px;
    overflow-y: auto;
    z-index: 50;
    top: 0 !important;
    bottom: inherit !important;

    .draftJsEmojiPlugin__emojiSuggestionsEntryFocused__XDntY {
      background-color: $darkblue;
    }
  }

  .draftJsMentionPlugin__mentionSuggestions__2DWjA {
    background-color: #484d52;
    border: 1px solid #62666b;
    box-shadow: none;
    border-radius: 8px;
    min-height: 40px;
    overflow-y: auto;
    z-index: 50;

    .draftJsMentionPlugin__mentionSuggestionsEntryFocused__3LcTd {
      background-color: $darkblue;
    }
  }
}
