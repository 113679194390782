.sidebar {
  width         : 320px;
  position      : fixed;
  top           : 0px;
  left          : 0;
  height        : 100%;
  z-index       : 50;
  background    : #fff;
  transition    : all 0.3s;
  overflow-y    : auto;
  padding-bottom: 80px;

  &.hide {
    margin-left: -320px;

    .sidebar-logo {
      position: absolute;
    }
  }
}

.sidebar-logo {
  position  : absolute;
  top       : 0;
  left      : 0;
  z-index   : 1;
  width     : 100%;
  max-width : 295px;
  background: #ffffff;
  padding   : 20px 0 0 20px;

  &.shadow {
    box-shadow: -3px 3px 5px #dedede;
  }
}

.user-component-detail {
  padding: 0;
  margin : 110px 20px 0 20px;
}

.order-filter-container {
  padding: 0 20px 0 20px;
}

.user-switch-view-container {
  padding         : 10px 20px 10px 20px;
  position        : fixed;
  bottom          : 0;
  width           : 320px;
  height          : 80px;
  background-color: #ffffff;
}

.user-component-detail .feather {
  width : 16px !important;
  height: 16px !important;
}

.user-component-detail .mt-3 {
  margin-top: 8px !important;
}

.user-component-detail hr {
  margin-top   : 8px !important;
  margin-bottom: 8px !important;
}

.btn-toggle-sidebar .feather {
  width    : 24px;
  height   : 24px;
  transform: rotate(180deg);
}

.sidebar-backdrop {
  position        : fixed;
  top             : 0;
  left            : 0;
  z-index         : -1;
  width           : 100vw;
  height          : 100vh;
  background-color: #000;
  opacity         : 0.5;
  display         : none;
}

@media only screen and (max-width: 991px) {
  .sidebar {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16), 0 0 1px 0 rgba(0, 0, 0, 0.16) !important;
  }

  .sidebar-backdrop {
    display: block;
    z-index: 40;
  }
}