.popover-notification-toolbar-cart {
  width: 270px;

  .img-prev-cart-item {
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .cart-items-wrapper {
    height: 150px;
    overflow: auto;
  }

  .btn-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    display: none;
  }

  .cart-items {
    li {
      &:hover {
        background-color: #f1f1f1;
        border-radius: 5px;

        .btn-icon {
          display: block;
        }
      }
    }
  }
}
