@import '../../scss/theme';

.page-signin {
  // background-color: $auth_background;
  background: linear-gradient(0deg, #53b5e6, #005794);
  position: relative;
}

.zindex-1 {
  z-index: 1;
}

.Auth {
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-image: url('/images/bg-top.png');
    background-repeat: no-repeat;
    background-position: 85% 100%;
    transform: rotate(180deg);
  }

  .header {
    padding: 21px 0 0 0;
    display: flex;
    flex-direction: row;

    .btn-register {
      min-width: 120px;
      font-size: 14px;
      font-weight: 600;
      padding: 7px 0;
    }
  }

  .nobreak {
    word-break: keep-all;
    white-space: nowrap;
  }

  .logo {
    padding: 21px 0 0 0;
  }

  .ornament {
    position: absolute;
    border-radius: 2rem;
    background: rgba(0, 176, 240, 0.5);
    transform: rotate(55deg);
    z-index: 0;
    right: 50px;
    bottom: -60%;
    height: 50rem;
    width: 40rem;
  }

  h1 {
    margin-bottom: 15px;
    color: #ffffff;
    font-family: 'Messina Sans';
    font-size: 36px;
    font-weight: bold;
    line-height: 47px;
    text-align: center;
  }

  p {
    margin-bottom: 35px;
    color: rgba(255, 255, 255, 0.7);
    font-family: 'Messina Sans';
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }

  .btn-link {
    color: #ffffff;
    font-family: 'Messina Sans';
    font-size: 14px;
    line-height: 24px;
    font-weight: normal;
  }

  .form-signin {
    padding: 30px 15px;
    max-width: 400px;
    height: 380px;
    // background-color   : #fff;
    // border-radius      : 10px;
    position: absolute;
    left: 50%;
    top: 150px;
    transform: translate(-50%, 0);

    label {
      color: rgba(255, 255, 255, 0.75);
      font-family: 'Messina Sans';
      font-size: 12px;
      line-height: 24px;
    }

    .form-group {
      margin-bottom: 25px;
    }

    .textbox-form-container {
      border: none;
      border-radius: 5px;
      background-color: #fff;
      overflow: hidden;

      input {
        color: #495057;
        font-family: 'Messina Sans';
        font-size: 14px;
        line-height: 24px;
        border: none;
        background: transparent;
        border-radius: 0;
        padding: 0 8px 0px 8px;
      }
    }

    .passsword-form-group {
      position: relative;
    }

    .password-show-hide {
      border: none;
      border-radius: 5px;
      background-color: #fff;
      overflow: hidden;
      position: static;

      input {
        color: #495057;
        font-family: 'Messina Sans';
        font-size: 14px;
        line-height: 24px;
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        background: transparent;
        border-radius: 0;
        padding: 0 8px 0px 8px;
        min-height: 35px;
      }

      .input-validation-msg {
        height: 29px;
        bottom: 5px;
        right: 0;
        top: unset;
      }

      .btn-icon {
        padding: 0;
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .btn-login {
      color: #ffffff;
      font-family: 'Messina Sans';
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      font-weight: normal;
      min-width: 150px;
      margin-top: 40px;
    }
  }
}
