.quick-search-result-wrapper {
  position  : absolute;
  left      : 0;
  top       : 100%;
  width     : 380px;
  display   : block;
  height    : 0;
  visibility: hidden;
  opacity   : 0;
  transition: all 300ms ease-in-out;

  &.show-result {
    height    : auto;
    visibility: visible;
    opacity   : 1;
  }

  .backdrop-search-result {
    position        : fixed;
    top             : 0;
    left            : 0;
    width           : 100vw;
    height          : 100vh;
    background-color: #000;
    z-index         : 1040;
    opacity         : 0.5;
  }

  ul {
    padding   : 0;
    margin    : 0;
    position  : relative;
    z-index   : 1050;
    margin-top: 10px;
    height    : 90vh;
    overflow  : auto;

    li.quick-search-result-item {
      list-style   : none;
      border-radius: 5px;
      border       : 1px solid rgba(0, 0, 0, 0.125) !important;
      background   : #fff;
      min-height   : 80px;

      .quick-search-result-item-wrapper {
        padding: 15px;

        .title-company {
          margin-bottom: 5px;
          padding-left: 23px;
        }

        .title-company .text-14 {
          font-size: 12px;
        }

        .card-content-info {
          margin-top: 0px !important;
        }
      }
    }
  }
}