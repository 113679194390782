@import '../../../scss/theme';

.membership-status {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: $black;
  border-radius: 10px;
  padding: 8px 10px 10px 10px;
  position: relative;
}

.membership-button,
.membership-button:hover {
  display: flex;
  flex-direction: row;
  background-color: var(--primary);
  color: white;
  border-radius: 10px;
  padding: 8px 10px 8px 10px;
  margin: 3px;
  position: relative;
  align-items: center;
  justify-content: space-evenly;
  text-decoration: none;
  font-size: 0.64rem;
}

.membership-button-disabled,
.membership-button-disabled:hover {
  display: flex;
  flex-direction: row;
  background-color: gray;
  color: white;
  border-radius: 10px;
  padding: 8px 10px 8px 10px;
  margin: 3px;
  position: relative;
  align-items: center;
  justify-content: space-evenly;
  text-decoration: none;
  font-size: 0.64rem;
}

.membership-button-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dot-extra-retail {
  height: 14px;
  width: 14px;
  background-color: rgb(196, 97, 221);
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}
.text-extra-retail {
  color: rgb(196, 97, 221);
}
.dot-subscription-retail {
  height: 14px;
  width: 14px;
  background-color: rgb(228, 107, 70);
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}
.text-subscription-retail {
  color: rgb(228, 107, 70);
}
